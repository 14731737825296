import type { MaybeRef } from 'vue'
import type { AvailablePurchaseOptionFixedPriceTicketDto } from '@peter-park/available-purchase-option'

export function useTicketsAreas(tickets: MaybeRef<AvailablePurchaseOptionFixedPriceTicketDto[]>) {
  /**
   * internal map to retrieve the area names of a ticket
   */
  const ticketsAreaNamesMap = computed(() => {
    const result = new Map<string, string[]>()
    for (const ticket of unref(tickets))
      result.set(ticket.id, ticket.areas?.map(t => t.name) ?? [])
    return result
  })

  /**
   * internal map to retrieve an area name by its id
   */
  const areaNameMap = computed(() => {
    const result = new Map<string, string>()
    for (const ticket of unref(tickets)) {
      for (const area of ticket.areas ?? []) {
        result.set(area.areaEntityId, area.name)
      }
    }
    return result
  })

  /**
   * A list of all the areas of the tickets
   */
  const ticketsAreas = computed(() => {
    return Array.from(areaNameMap.value.entries()).map(([id, name]) => ({ id, name }))
  })

  /**
   * returns the area names of the ticket
   * @param ticket the ticket to get the area names
   * @returns an array of area names
   */
  function getTicketAreaNames(ticket: AvailablePurchaseOptionFixedPriceTicketDto) {
    return ticketsAreaNamesMap.value.get(ticket.id) ?? []
  }

  return { ticketsAreas, getTicketAreaNames }
}
