<script setup lang="ts">
import { agbLinks, getLink, privacyLinks } from '@/types'

defineOptions({
  name: 'AppFooter',
})

const props = defineProps<{
  shopCountry?: string
}>()
const { t } = useI18n()

const legalSection = computed(() => {
  return [
    { name: t('footer.terms_and_conditions'), href: getLink(agbLinks, props.shopCountry), blank: true },
    { name: t('footer.privacy_and_policy'), href: getLink(privacyLinks, props.shopCountry), blank: true },
    { name: t('footer.imprint'), href: 'https://www.mh-parkservice.de/impressum', blank: true },
  ]
})
</script>

<template>
  <footer class="mt-20 rounded-t-xl bg-gray-700 px-5 py-6" aria-labelledby="footer-heading">
    <div class="mx-auto container lg:pt-16">
      <div class="lg:grid lg:grid-cols-3 lg:gap-12">
        <div class="md-col-span-2">
          <img class="h-auto w-45" src="@/assets/logo_dark.svg" alt="Company name">
        </div>
        <div class="grid grid-cols-2 mt-16 gap-8 lg:col-span-1 lg:mt-0">
          <div>
            <ul role="list" class="mt-6 space-y-4">
              <li v-for="item in legalSection" :key="item.name">
                <a :href="item.href" class="text-sm text-gray-200 leading-6 hover:text-white" :target="item.blank ? '_blank' : ''">{{ item.name }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="mx-auto py-6 container md:grid md:grid-cols-3 md:gap-12 lg:py-14">
      <div class="md-col-span-2">
        <p class="text-xs text-gray-300 leading-5">
          &copy; {{ $t('footer.copyright') }}
        </p>
      </div>
    </div>
  </footer>
</template>
