export const supportedLocations = ['de', 'it', 'ch', 'at']

export type SupportedLocations = typeof supportedLocations[number]

export const location = window.location.host.split('.').pop() || 'de'

export const privacyLinks = new Map<SupportedLocations, string>([
  ['de', 'https://mh-parkservice.de/datenschutz'],
  ['it', 'https://it.mh-parkservice.de/protezione-dei-dati'],
  ['ch', 'https://ch.mh-parkservice.de/datenschutz'],
  ['at', 'https://at.mh-parkservice.de/datenschutz'],
])

export const agbLinks = new Map<SupportedLocations, string>([
  ['de', 'https://mh-parkservice.de/agb-online'],
  ['it', 'https://www.mh-parkservice.it/agb-online'],
  ['ch', 'https://www.mh-parkservice.ch/agb-online'],
  ['at', 'https://at.mh-parkservice.de/agb-online'],
])

export function getLink(linkArray: Map<SupportedLocations, string>, shopCountry?: SupportedLocations) {
  const location = shopCountry?.toLowerCase()

  if (location && supportedLocations.includes(location))
    return linkArray.get(location as SupportedLocations)

  // Fallback to German
  return linkArray.get('de')
}
