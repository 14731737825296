<script lang="ts" setup>
import {
  SelectContent,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectPortal,
  SelectRoot,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from 'radix-vue'

const props = defineProps<{
  /**
   * list of areas to show in the select
   */
  areas: {
    id: string
    name: string
  }[]
}>()

/**
 * The id of the selected area
 */
const selectedArea = defineModel<string>()

const { t } = useI18n()
</script>

<template>
  <SelectRoot v-model="selectedArea">
    <SelectTrigger class="h-9 max-w-md w-full inline-flex items-center justify-between gap-2 border border-gray-100/50 rounded bg-white px-4 leading-none outline-none">
      <SelectValue :placeholder="t('select_an_area')" />
      <div
        class="i-lucide:chevron-down h-3.5 w-3.5"
      />
    </SelectTrigger>

    <SelectPortal>
      <SelectContent
        position="popper"
        :side-offset="5"
        class="SelectContent will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade z-100 border border-gray-100/50 rounded bg-white shadow-sm"
      >
        <SelectViewport>
          <SelectItem
            v-for="area in props.areas" :key="area.id" :value="area.id"
            class="relative h-6 flex select-none items-center rounded-2 pl-6 pr-10 leading-none data-[disabled]:pointer-events-none data-[highlighted]:bg-primary-500 data-[highlighted]:text-white data-[highlighted]:outline-none"
          >
            <SelectItemIndicator class="absolute left-0 w-6 inline-flex items-center justify-center">
              <div class="i-lucide:check" />
            </SelectItemIndicator>
            <SelectItemText>{{ area.name }}</SelectItemText>
          </SelectItem>
        </SelectViewport>
      </SelectContent>
    </SelectPortal>
  </SelectRoot>
</template>
